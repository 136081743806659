import { type GroupGroup } from '../../../../../../types/canonical/GroupGroup';
import { type GroupModule } from '../../../../../../types/canonical/GroupModule';
import { type StudyGroup } from '../../../../../../types/canonical/StudyGroup';

export type ProductTreeNodeType = StudyGroup | GroupGroup | GroupModule;

export enum ProductTreeNodeTypeEnum {
  STUDY_OR_GROUP_TO_GROUP = 'STUDY_OR_GROUP_TO_GROUP',
  GROUP_TO_MODULE = 'GROUP_TO_MODULE',
  OBJECTIVE = 'OBJECTIVE'
}
