import { create } from 'zustand';

interface UseGlobalLoaderState {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  transitionEnded: boolean;
  setTransitionEnded: (transitionEnded: boolean) => void;
}

const useGlobalLoader = create<UseGlobalLoaderState>((set) => ({
  isLoading: true,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  transitionEnded: false,
  setTransitionEnded: (transitionEnded: boolean) =>
    set(() => ({ transitionEnded }))
}));

export default useGlobalLoader;
