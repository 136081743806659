import { chain, get, isEmpty } from 'lodash';
import { getProperties, matchFilter } from '../../../filters/Filters';
import { displayName } from '../../../references/Reference';
import { getReferenceById } from '../../../references/ReferenceService';
import { useReferences } from '../../../references/useReferences';
import type Appraisal from '../../../types/canonical/Appraisal';
import { getRawValue } from '../../../types/canonical/Value';
import { type WidgetType } from '../../types/WidgetType';
import { type WidgetField } from '../../WidgetField/WidgetField';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  appraisals: Appraisal[];
  year: string;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function AssessmentsItem({
  field,
  type,
  appraisals,
  year,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { references } = useReferences(field.referenceType, year);

  const values = chain(appraisals)
    .filter((schema) => {
      const properties = getProperties(schema.assessment);
      return matchFilter(field.filter, properties);
    })
    .map((schema: Appraisal) => {
      const value = getRawValue(field.name, schema.assessment?.values);
      const reference = getReferenceById(references, value);
      return {
        displayName: displayName(value, references, value),
        sequence: get(reference, 'sequence', -1),
        visible: get(reference, 'visible', true)
      };
    })
    .filter((item) => item.visible && !isEmpty(item.displayName))
    .orderBy(['sequence', 'displayName'])
    .map('displayName')
    .value();

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(values)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() =>
        !values?.length ? (
          <></>
        ) : (
          <>
            {values.map((value, index) => (
              <div key={`assessment-${index}`}>{value}</div>
            ))}
          </>
        )
      }
    </FieldSection>
  );
}
