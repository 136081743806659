import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { toLogout } from '../../../../../../authentication/Logout/links';

/**
 * The `LogoutDropdownItem` redirects the user to the `Logout` page
 * when the `DropdownItem` is clicked.
 *
 * See the Logout component as to why the `LogoutDropdownItem` does
 * not actually log out the user itself.
 */
export default function LogoutDropdownItem() {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  async function onLogout() {
    navigate(toLogout());
  }

  return (
    <DropdownItem
      className="clickable"
      tag="div"
      color="link"
      onClick={onLogout}
    >
      {t('LOGIN.LOGOUT')}
    </DropdownItem>
  );
}
