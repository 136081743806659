import { WidgetTitle } from '../components/WidgetTitle';
import { type WidgetRenderVariantProps } from '../types/WidgetRenderVariantProps';
import { MatrixWidgetContainer } from './MatrixWidgetContainer/MatrixWidgetContainer';

interface MatrixWidgetProps extends WidgetRenderVariantProps {}

export function MatrixWidget({ widget, product }: Readonly<MatrixWidgetProps>) {
  return (
    <div className="mt-2">
      <WidgetTitle widget={widget} />
      <MatrixWidgetContainer
        widget={widget}
        product={product}
      />
    </div>
  );
}
