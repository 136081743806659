import { type AuthenticationState, getService } from '@42.nl/authentication';
import { useQuery } from '@tanstack/react-query';
import { type CurrentUser } from '../users/CurrentUser';
import { getAuthenticationService } from './AuthenticationService';

export function useAuthentication(): AuthenticationState<CurrentUser> {
  const { data } = useQuery({
    queryKey: ['auth'],
    queryFn: async () => await getAuthenticationService().getAuthentication()
  });

  if (!data) {
    return getService<CurrentUser>().getState();
  }

  return data;
}
