import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import type AcademicYear from '../../../types/AcademicYear';
import { getName } from '../../../types/Name';
import { getYearService } from '../../YearService';

type Props = {
  externalId?: string;
  initYears?: AcademicYear[];
  className?: string;
  onChange: (name: string, value: string) => void | undefined;
};

export default function YearDropdown({
  externalId,
  initYears = getYearService().getYears(),
  className,
  onChange
}: Readonly<Props>) {
  const [currentYear, setCurrentYear] = useState<AcademicYear>();
  const [years, setYears] = useState<AcademicYear[]>();

  useEffect(() => {
    setYears(
      initYears.filter((year) => getYearService().isVisible(year.externalId))
    );
  }, [initYears]);

  useEffect(() => {
    if (externalId && getYearService().isVisible(externalId)) {
      setCurrentYear(getYearService().getYearByExternalId(externalId));
    } else {
      const year = getYearService().getCurrentYear();
      if (year) {
        setCurrentYear(year);
      }
    }
  }, [externalId, onChange]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  function toggleSortDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  return years !== undefined && years.length > 1 ? (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleSortDropdown}
      className={classNames('my-0 w-auto no-separator', className)}
      direction="down"
    >
      <div className="d-inline-block">
        <DropdownToggle
          caret
          className="px-0 bg-transparent text-dark border-0"
        >
          <YearLabel year={currentYear} />
        </DropdownToggle>
      </div>

      <DropdownMenu>
        {years.map((year) => (
          <DropdownItem
            key={year.externalId}
            active={currentYear?.externalId === year.externalId}
            className="clickable"
            tag="div"
            color="link"
            onClick={() => {
              onChange('year', year.externalId ? year.externalId : '');
            }}
          >
            {getName(year)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  ) : (
    <YearLabel
      year={currentYear}
      className={className}
    />
  );
}

type LabelProps = {
  year?: AcademicYear;
  className?: string;
};

function YearLabel({ year, className }: Readonly<LabelProps>) {
  const { t } = useTranslation(['translation']);

  return (
    <h4 className={classNames('d-inline-block font-lg-strong', className)}>
      {t('translation:YEAR')}: {getName(year)}
    </h4>
  );
}
