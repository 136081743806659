import _, { find, isEmpty } from 'lodash';

export default class Value {
  name!: string;
  type?: string;
  values!: [string];
  description?: string;
}

export function getRawValue(name: string, values: Value[] | undefined) {
  return _(values)
    .filter({ name })
    .flatMap((item) => item.values)
    .find();
}

export function getValue(name: string, values: Value[] | undefined) {
  const value = find(values, { name });
  if (!value) {
    return '';
  }

  if (!isEmpty(value.description)) {
    return value.description;
  }

  return value.values.join(', ');
}
