import { type Key } from 'react';
import { type ProductTree } from '../../../ProductTreeStore/types/ProductTree';
import { type ProductTreeNode } from '../../../ProductTreeStore/types/ProductTreeNode';
import { ProductTreeNodeTypeEnum } from '../../../ProductTreeStore/types/ProductTreeNodeType';
import { ProductTreeBulkToggleMode } from '../types/ProductTreeBulkToggleMode';

const collectRelevantKeys = (
  nodes: ProductTree,
  predicate: (node: ProductTreeNode) => boolean
): Key[] => {
  return nodes.flatMap((node: ProductTreeNode) => [
    ...(predicate(node) ? [node.key] : []),
    ...(node.children ? collectRelevantKeys(node.children, predicate) : [])
  ]);
};

const expandAllKeys = (productTree: ProductTree) =>
  collectRelevantKeys(productTree, () => true);

const expandKeysByType = (
  productTree: ProductTree,
  expandedRowKeys: Key[],
  type: ProductTreeNodeTypeEnum
): Key[] => {
  const keysToAdd = collectRelevantKeys(
    productTree,
    (node) => node.type === type
  );
  return Array.from(new Set([...expandedRowKeys, ...keysToAdd]));
};

const collapseKeysByType = (
  productTree: ProductTree,
  expandedRowKeys: Key[],
  type: ProductTreeNodeTypeEnum
): Key[] => {
  const keysToRemove = collectRelevantKeys(
    productTree,
    (node) => node.type === type
  );
  return expandedRowKeys.filter((key) => !keysToRemove.includes(key));
};

const collapseAllKeys = () => [];

const productTreeBulkToggleReducer = (
  productTree: ProductTree,
  expandedRowKeys: Key[],
  action: ProductTreeBulkToggleMode
): Key[] => {
  switch (action) {
    case ProductTreeBulkToggleMode.EXPAND_ALL:
      return expandAllKeys(productTree);

    case ProductTreeBulkToggleMode.COLLAPSE_ALL:
      return collapseAllKeys();

    case ProductTreeBulkToggleMode.EXPAND_GROUPS:
      return expandKeysByType(
        productTree,
        expandedRowKeys,
        ProductTreeNodeTypeEnum.STUDY_OR_GROUP_TO_GROUP
      );

    case ProductTreeBulkToggleMode.COLLAPSE_GROUPS:
      return collapseKeysByType(
        productTree,
        expandedRowKeys,
        ProductTreeNodeTypeEnum.STUDY_OR_GROUP_TO_GROUP
      );

    case ProductTreeBulkToggleMode.EXPAND_MODULES:
      return expandKeysByType(
        productTree,
        expandedRowKeys,
        ProductTreeNodeTypeEnum.GROUP_TO_MODULE
      );

    case ProductTreeBulkToggleMode.COLLAPSE_MODULES:
      return collapseKeysByType(
        productTree,
        expandedRowKeys,
        ProductTreeNodeTypeEnum.GROUP_TO_MODULE
      );

    default:
      return expandedRowKeys;
  }
};

export default productTreeBulkToggleReducer;
