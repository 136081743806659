import { memo } from 'react';
import { Table } from '../../../../components/Table/Table';
import { type WidgetRenderVariantProps } from '../../types/WidgetRenderVariantProps';
import useProductTree from '../ProductTree/ProductTreeStore/hooks/useProductTree.hook';
import useProductTreeColumns from '../ProductTree/ProductTreeStore/hooks/useProductTreeColumns.hook';
import { useProductTreeData } from '../ProductTree/ProductTreeStore/hooks/useProductTreeData.hook';
import { type ProductTreeNode } from '../ProductTree/ProductTreeStore/types/ProductTreeNode';

interface MatrixWidgetTableProps extends WidgetRenderVariantProps {}

const MatrixWidgetTable = ({
  product,
  widget
}: Readonly<MatrixWidgetTableProps>) => {
  const columns = useProductTreeColumns(product, widget);

  const {
    productTree,
    currentProductUid,
    expandedRowKeys,
    updateExpandedRowKeys
  } = useProductTree(product, product.data.uid);
  const { onNodeToggle } = useProductTreeData(product.data.year.id);

  const handleExpand = (expanded: boolean, record: ProductTreeNode) => {
    onNodeToggle(record.key).then(() => {
      const newKeys = expanded
        ? [...expandedRowKeys, record.key]
        : expandedRowKeys.filter((key) => key !== record.key);

      updateExpandedRowKeys(newKeys);
    });
  };

  const key: keyof ProductTreeNode = 'key';

  if (!productTree || !currentProductUid) {
    return null;
  }

  return (
    <Table<ProductTreeNode>
      dataSource={productTree}
      columns={columns}
      tableLayout="fixed"
      pagination={false}
      bordered
      sticky
      scrollable
      size={'middle'}
      rowKey={key}
      indentSize={17}
      expandable={{
        onExpand: handleExpand,
        expandRowByClick: true,
        expandedRowKeys
      }}
    />
  );
};

// Improve performance without using virtualization
export default memo(MatrixWidgetTable);
