import { setErrors } from '@42.nl/react-error-store';
import { addError } from '@42.nl/react-flash-messages';
import { get, isFunction } from 'lodash';

export function errorMiddleware(error: any): Promise<any> {
  if (
    error.response &&
    error.response.status &&
    isFunction(error.response.json)
  ) {
    switch (error.response.status) {
      case 403:
        return error.response.json().then(() => {
          addError({ text: 'You are not authorized to perform this action.' });
          return Promise.reject(error);
        });
      case 422:
        error.response.json().then((json: any) => {
          const globalErrors = get(json, 'globalErrors', []);

          globalErrors.forEach((message: any) =>
            addError({ text: `An error occurred: ${message}` })
          );

          const fieldErrors = get(json, 'fieldErrors', {});
          setErrors(fieldErrors);
        });
        break;
      default:
        break;
    }
  }

  // Keep the chain alive.
  return Promise.reject(error);
}
