import { get, makeResource } from '@42.nl/spring-connect';
import { type QueryParams, search } from '../filters/Search';
import type AcademicYear from './AcademicYear';
import { type ProductData } from './canonical/ProductData';
import { type ProductDetailQueryParams, ProductTypeEnum } from './Product';

const baseUrl = '/api/faculty';

export default class Faculty extends makeResource<Faculty>(baseUrl) {
  id!: number;
  data!: ProductData;
  productType!: string;

  static async search(queryParams: QueryParams) {
    return search<Faculty>(ProductTypeEnum.FACULTY, baseUrl, queryParams);
  }

  static async findAll(
    queryParams: ProductDetailQueryParams
  ): Promise<Faculty[]> {
    return get(baseUrl, queryParams);
  }

  static years(code: string): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${code}/years`);
  }

  static readonly QUERY_KEY = 'faculties';
}
