import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Compact } from '../../../components';
import type Reference from '../../../references/Reference';
import type Description from '../../../types/canonical/Description';
import { getDescriptionText } from '../../../types/canonical/Description';
import { getName } from '../../../types/Name';

type Props = {
  type?: Reference;
  descriptions: Description[];
  expanded?: boolean;
  className?: string;
};

export default function DescriptionItem({
  type,
  descriptions,
  expanded,
  className
}: Readonly<Props>) {
  const { t } = useTranslation('translation');

  const descriptionText = getDescriptionText(
    descriptions,
    t('NO_CURRENT_LANGUAGE_INFO')
  );

  if (!descriptionText) return <></>;

  return (
    <div className={classNames('details__content__main__section', className)}>
      <div className="d-flex flex-row">
        <h2 className="details__content__main-name d-flex flex-column flex-grow-1 mt-2">
          {getName(type)}
        </h2>
      </div>
      <div style={{ fontSize: '1.5rem' }}>
        <Compact expanded={expanded}>
          <div className="details__content__main__section__value">
            {descriptionText}
          </div>
        </Compact>
      </div>
    </div>
  );
}
