import { useMemo } from 'react';
import { type Widget } from '../types/Widget';
import { getVisibleSortedWidgetFields } from '../utils/WidgetUtils';
import { type WidgetField } from '../WidgetField/WidgetField';
import { useWidgetGroups } from './useWidgetGroups.hook';

export function usePrimaryWidgetFields(widgets: Widget[]): WidgetField[] {
  const groupedWidgets = useWidgetGroups(widgets);

  return useMemo(() => {
    return groupedWidgets.primary.flatMap((widget) =>
      getVisibleSortedWidgetFields(widget)
    );
  }, [groupedWidgets.primary]);
}
