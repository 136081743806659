import { isEmpty } from 'lodash';
import { Loader } from '../../components';
import { type Product } from '../../types/Product';
import { useVisibleSortedWidgetsForTab } from '../../widgets/hooks/useVisibleSortedWidgetsForTab.hook';
import ProductHeaderFallback from './ProductHeaderFallback';
import ProductHeaderName from './ProductHeaderName';

type ProductHeaderProps = {
  product: Product;
  tabId?: number;
};

export default function ProductHeader({
  product,
  tabId
}: Readonly<ProductHeaderProps>) {
  const mainTabWidgetsState = useVisibleSortedWidgetsForTab(tabId);

  return (
    <Loader
      state={mainTabWidgetsState}
      isEmpty={(data) => isEmpty(data)}
      emptyContent={() => <ProductHeaderFallback product={product} />}
    >
      {(widgets) => (
        <ProductHeaderName
          product={product}
          widgets={widgets}
        />
      )}
    </Loader>
  );
}
