import { Icon } from '@42.nl/ui';

type Props = {
  collapsed: boolean;
  size?: number;
};

export function Expander({ collapsed, size }: Readonly<Props>) {
  return (
    <Icon
      icon={collapsed ? 'expand_more' : 'expand_less'}
      size={size || 42}
      className="expander-icon d-print-none"
    />
  );
}
