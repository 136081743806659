import { sortBy, uniqueId } from 'lodash';
import type { StudyGroup } from '../../../../../../types/canonical/StudyGroup';
import {
  type Product,
  type ProductType,
  ProductTypeEnum,
  productTypeMatches
} from '../../../../../../types/Product';
import { getGroups, getModules } from '../../../Subjects/subjectUtils';
import { type ProductTree } from '../types/ProductTree';
import type { ProductTreeNode } from '../types/ProductTreeNode';
import { ProductTreeNodeTypeEnum } from '../types/ProductTreeNodeType';
import { getProductTreeNodeCompositeUid } from './getProductTreeNodeCompositeUid';
import {
  getProductTreeNodeTypeName,
  isProductTreeNodeGroup,
  isProductTreeNodeModule
} from './productTreeNodeUtils';

const buildTopLevelProductTree = async (
  initialGroups: StudyGroup[],
  product: Product,
  detailPageProductType: ProductType,
  fetchGroupModules: (group: ProductTreeNode) => Promise<ProductTreeNode[]>,
  parentCompositeUID: string = ''
): Promise<ProductTree> => {
  const sortKeys: (keyof StudyGroup)[] = ['sequence', 'required', 'phase'];
  const sortedNodes = sortBy(initialGroups, (node) =>
    sortKeys.map((key) => node[key])
  );

  const treeNodes = await Promise.all(
    sortedNodes.map(async (node): Promise<ProductTreeNode> => {
      const compositeUid = getProductTreeNodeCompositeUid(
        parentCompositeUID,
        node.uid
      );

      const isGroup = isProductTreeNodeGroup(node);
      const isModule = isProductTreeNodeModule(node);
      const typeName = getProductTreeNodeTypeName(isGroup, isModule);

      let children: ProductTreeNode[] = [];
      if (isGroup) {
        const groups = getGroups(node);
        const modules = getModules(node);

        const childNodes = await buildTopLevelProductTree(
          groups,
          product,
          detailPageProductType,
          fetchGroupModules,
          compositeUid
        );

        const moduleNodes = modules.map((module) => ({
          ...module,
          key: getProductTreeNodeCompositeUid(compositeUid, module.uid),
          type: ProductTreeNodeTypeEnum.GROUP_TO_MODULE,
          structure: module
        }));

        children = [...childNodes, ...moduleNodes];
      }

      return {
        ...node,
        key: compositeUid,
        type: typeName,
        structure: node,
        product: {
          id: node.group?.key ?? parseInt(uniqueId(), 10),
          data: node.group,
          productType: ProductTypeEnum.GROUP
        },
        ...(children.length > 0 ? { children } : {})
      };
    })
  );

  if (productTypeMatches(detailPageProductType, ProductTypeEnum.GROUP)) {
    const fetchedModules = await fetchGroupModules({
      key: parentCompositeUID,
      uid: parentCompositeUID,
      type: ProductTreeNodeTypeEnum.GROUP_TO_MODULE,
      product: {
        id: product.id,
        data: product.data,
        productType: ProductTypeEnum.GROUP
      }
    });
    return [...treeNodes, ...fetchedModules];
  }

  return treeNodes;
};

export default buildTopLevelProductTree;
