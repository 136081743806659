import type SimpleGroup from './SimpleGroup';
import type SimpleStudy from './SimpleStudy';

export class GroupStudy {
  group!: SimpleGroup;
  study!: SimpleStudy;
}

export function getUniqueKey(groupStudy: GroupStudy) {
  return `${groupStudy.study.data.code}-${groupStudy.group.data.code}`;
}
