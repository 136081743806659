import { useMemo } from 'react';
import ProductTypeIcon from '../../../../../../components/Icon/product/productType/ProductTypeIcon.component';
import RequiredIcon from '../../../../../../components/Icon/required/Required.icon';
import useWidgetColumns from '../../../../../../components/Table/hooks/useWidgetColumns.hook';
import { type StrictColumnsType } from '../../../../../../components/Table/types/StrictColumnsType';
import { ProductLink } from '../../../../../../product/ProductLink';
import { type Product } from '../../../../../../types/Product';
import { type Widget } from '../../../../../types/Widget';
import { useCategorizedSubjectColumns } from '../../../Subjects/hooks/useCategorizedSubjectColumns.hook';
import { useMatrixWidgetFields } from '../../../Subjects/hooks/useSubjectTypeWidgetFields.hook';
import { type ProductTreeNode } from '../types/ProductTreeNode';

export default function useProductTreeColumns(
  product: Product,
  widget: Widget
): StrictColumnsType<ProductTreeNode> {
  const BaseColumns: StrictColumnsType<ProductTreeNode> = useMemo(() => {
    return [
      {
        key: 'info',
        minWidth: 360,
        className: 'matrix-widget__cell matrix-widget__cell--info',
        render: (node: ProductTreeNode) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <ProductTypeIcon icon={node.type} />
              <RequiredIcon isRequired={node.structure?.required} />
              <ProductLink product={node.product} />
            </div>
          );
        }
      }
    ];
  }, []);

  const matrixWidgetFields = useMatrixWidgetFields([widget]);
  const WidgetColumns: StrictColumnsType<ProductTreeNode> =
    useWidgetColumns<ProductTreeNode>({
      fields: matrixWidgetFields,
      enableStickyColumns: false,
      minWidth: 100,
      width: 140,
      cellClassName:
        'matrix-widget__cell matrix-widget__cell--hover matrix-widget__cell--widget'
    });

  const ScaleColumns = useCategorizedSubjectColumns(product, widget);

  return useMemo(() => {
    return [...BaseColumns, ...WidgetColumns, ...ScaleColumns];
  }, [BaseColumns, WidgetColumns, ScaleColumns]);
}
