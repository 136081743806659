import { ConfirmButton } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  onDelete: () => void;
};

export function DeleteConfirmButton({ name, onDelete }: Readonly<Props>) {
  const { t } = useTranslation('translation');

  return (
    <ConfirmButton
      className="d-inline-block"
      onConfirm={onDelete}
      dialogText={t('translation:DELETE_CONFIRMATION', { name })}
      icon="delete"
    />
  );
}
