import { addSuccess } from '@42.nl/react-flash-messages';
import { type Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { AdminButton } from '../../components/AdminButton/AdminButton';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { PrintButton } from '../../components/Print/PrintButton';
import { structureStore } from '../../groups/GroupStructure/structureStore';
import ProductHeader from '../../product/ProductHeader/ProductHeader';
import { ProductPrint } from '../../product/ProductPrint';
import { useActiveMainTab } from '../../tabs/context/hooks/useActiveMainTab';
import { useSubTabs } from '../../tabs/context/hooks/useSubTabs';
import SubTabsNav from '../../tabs/SubTabsNav';
import { getActiveSubTab } from '../../tabs/utils/subTabUtils';
import type Study from '../../types/canonical/Study';
import {
  type ProductDetailPathParams,
  type ProductDetailQueryParams,
  useProductQuery
} from '../../types/Product';
import SimpleStudy from '../../types/SimpleStudy';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import ProductDetailYearDropdown from '../../years/components/YearDropdown/ProductDetailYearDropdown';
import { STUDY_APP_URL, toStudiesApp } from '../StudyLinks';
import { defaultStudyDetailQueryParams } from './StudyDetailQueryParams';

export async function loadStudy(
  code: string,
  queryParams: ProductDetailQueryParams
) {
  return SimpleStudy.one(code, queryParams);
}

export default function StudyDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useQueryParams<ProductDetailQueryParams>({
    location,
    defaultQueryParams: defaultStudyDetailQueryParams(),
    debugName: 'StudyDetail'
  });

  const { code = '' } = useParams<ProductDetailPathParams>();
  const { t } = useTranslation(['translation', 'study', 'import']);

  const productState = useProductQuery<SimpleStudy>({
    productType: 'study',
    loadFunction: loadStudy,
    code,
    queryParams,
    filterChanged
  });

  const activeMainTab = useActiveMainTab();
  const subTabs = useSubTabs();
  const activeSubTab = getActiveSubTab(subTabs, queryParams.tab);

  function filterChanged(name: string, value: string | number) {
    navigate(toStudyDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });
  }

  async function importStudy(study: SimpleStudy) {
    try {
      await SimpleStudy.import(study);
      addSuccess({
        text: t('import:STARTED_MESSAGE')
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return (
    <Loader
      state={productState}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toStudiesApp(toQueryParams(activeMainTab, queryParams.year))}
          replace
        />
      )}
    >
      {(study) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toStudiesApp(
                    toQueryParams(activeMainTab, study.data.year.id)
                  )}
                >
                  {t('study:STUDIES')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{study.data.code}</BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details__content__main me-auto"
            >
              <Provider store={structureStore}>
                <ProductHeader
                  product={study}
                  tabId={activeMainTab?.id}
                />

                <Row style={{ minHeight: '46px' }}>
                  <Col
                    m={12}
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <ProductDetailYearDropdown
                      yearExternalId={queryParams.year}
                      productYearsPromise={SimpleStudy.years}
                      productCode={study.data.code}
                      onChange={filterChanged}
                    />
                    <WidgetButtons product={study}>
                      <AdminButton
                        label={t('import:BUTTON')}
                        onClick={() => importStudy(study)}
                      />
                      <PrintButton>
                        <ProductPrint
                          tabs={subTabs}
                          product={study}
                        />
                      </PrintButton>
                    </WidgetButtons>
                  </Col>
                </Row>

                <SubTabsNav
                  subTabs={subTabs}
                  activeTabName={activeSubTab?.name}
                  product={study}
                  toggle={filterChanged}
                />

                <TabContent activeTab={activeSubTab?.name}>
                  {subTabs.map((subTab) => (
                    <TabPane
                      tabId={subTab.name}
                      key={subTab.id}
                    >
                      <WidgetDetail
                        tabId={subTab.id}
                        product={study}
                        expanded={false}
                      />
                    </TabPane>
                  ))}
                </TabContent>
              </Provider>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const STUDY_DETAIL_URL = `${STUDY_APP_URL}/:code`;

function toStudyDetail(
  pathParams: ProductDetailPathParams,
  queryParams: ProductDetailQueryParams
): Url {
  return urlBuilder({
    url: STUDY_DETAIL_URL,
    pathParams,
    queryParams
  });
}

export function studyDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & Study) {
  return toStudyDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
