import Main from './components/Main/Main';

type Props = {
  children?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
  title?: string;
};

export default function AppFrame(props: Readonly<Props>) {
  const { breadcrumbs, children, title } = props;

  return (
    <Main
      breadcrumbs={breadcrumbs}
      title={title}
    >
      {children}
    </Main>
  );
}
