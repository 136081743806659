import { Spin } from 'antd';
import type { SpinProps } from 'antd/es/spin';

export default function Loader({ size = 'large', ...props }: SpinProps) {
  return (
    <Spin
      size={size}
      {...props}
    />
  );
}
