import _, { isEmpty } from 'lodash';
import { getText } from '../../i18n/LocalizedText';
import type Reference from '../../references/Reference';
import { displayName } from '../../references/Reference';
import { getReferenceById } from '../../references/ReferenceService';
import type Period from './Period';
import type Value from './Value';

export default class Offering {
  period?: Period;
  blocks?: Period[];
  partOfDay?: string;
  description?: string;
  values?: Value[];
  startDate!: Date;
  endDate!: Date;
}

export function getPeriodsDisplayName(
  offerings: Offering[] | undefined,
  references: Reference[]
) {
  return _(offerings || [])
    .orderBy(['startDate', 'endDate'], ['asc', 'desc'])
    .map((offering) => getPeriodDisplayName(offering, references))
    .filter((period) => !isEmpty(period))
    .uniq()
    .value();
}

export function getPeriodDisplayName(
  offering: Offering,
  references: Reference[]
): string {
  if (offering.blocks && offering.blocks.length > 0) {
    return _(offering.blocks)
      .map((block) => displayName(block.id, references, block.code))
      .uniq()
      .sort()
      .join('+');
  }

  return getText(offering.period?.names, offering.period?.code);
}

export function getTimeBlocks(
  offerings: Offering[] | undefined,
  timeblocks: Reference[]
) {
  return _(offerings || [])
    .flatMap((offering) => (!isEmpty(offering.blocks) ? offering.blocks : []))
    .map((block) => getReferenceById(timeblocks, block?.id))
    .filter((reference) => !isEmpty(reference))
    .orderBy('sequence')
    .value();
}
