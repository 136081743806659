import LinkLabel from '../../../LinkLabel/LinkLabel';

const linkLabels = [
  { labelKey: 'FOOTER.DISCLAIMER.LABEL', urlKey: 'FOOTER.DISCLAIMER.URL' },
  { labelKey: 'FOOTER.PRIVACY.LABEL', urlKey: 'FOOTER.PRIVACY.URL' },
  { labelKey: 'FOOTER.COOKIES.LABEL', urlKey: 'FOOTER.COOKIES.URL' },
  { labelKey: 'FOOTER.COPYRIGHT.LABEL', urlKey: 'FOOTER.COPYRIGHT.URL' }
];

export default function Footer() {
  return (
    <div
      id="footer"
      className="sg-py-10"
    >
      <div className="sg-flex sg-w-full font-base-normal sg-max-w-[1170px] sg-mx-auto">
        {linkLabels.map((link) => (
          <LinkLabel
            key={link.labelKey}
            labelKey={link.labelKey}
            urlKey={link.urlKey}
            namespace="footer"
          />
        ))}
      </div>
    </div>
  );
}
