import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getLanguages } from '../../../core/i18n';

type QueryName = 'dutchName' | 'englishName';

type Props = {
  className?: string;
};

export default function LanguageSwitcher({ className }: Readonly<Props>) {
  const { i18n } = useTranslation();

  const onLanguage = (language: string) => {
    if (i18n.language === language) return;
    i18n.changeLanguage(language);
  };

  const languages = getLanguages();
  if (languages.length <= 1) {
    return <></>;
  }

  return (
    <span className="d-flex gap-3">
      {languages.map((language) => (
        <button
          key={language}
          onClick={() => onLanguage(language)}
          className={classNames(
            `${i18n.language === language ? '!sg-no-underline hover:!sg-cursor-default sg-font-bold' : ''}`,
            'nav-link p-1 border-0 bg-transparent',
            {
              'text-dark': i18n.language === language,
              'text-decoration-underline color-primary':
                i18n.language !== language
            },
            className
          )}
        >
          {language.toUpperCase()}
        </button>
      ))}
    </span>
  );
}

export function languageCodeToQueryName(languageCode: string): QueryName {
  return languageCode === 'en' ? 'englishName' : 'dutchName';
}
