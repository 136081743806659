import { getText, type LocalizedText } from '../LocalizedText';

interface Props {
  names?: LocalizedText[];
}

export default function LanguageLabel({ names }: Readonly<Props>) {
  const name = getText(names);
  return <span>{name}</span>;
}
