import React, { useEffect, useState } from 'react';
import { Icon } from '@42.nl/ui';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  expand: boolean;
  showLabel?: boolean;
  className?: string;
  onToggle: (expand: boolean) => void;
};

export function ExpandAll({
  expand,
  showLabel = true,
  className,
  onToggle
}: Readonly<Props>) {
  const [expanded, setExpanded] = useState(expand);
  const { t } = useTranslation('translation');

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  function toggle(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    event.preventDefault();
    onToggle(!expanded);
  }

  function getLabel() {
    if (!showLabel) {
      return null;
    }

    return expanded
      ? t('translation:COLLAPSE_ALL')
      : t('translation:EXPAND_ALL');
  }

  return (
    <div
      className={classNames(
        'd-flex justify-content-start clickable d-print-none',
        className
      )}
      onClick={toggle}
    >
      {expanded ? <Icon icon="unfold_less" /> : <Icon icon="unfold_more" />}
      {getLabel()}
    </div>
  );
}
