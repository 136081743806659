import { useEffect, useMemo, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { ConfigProvider, Select, type SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { type ProductType } from '../../types/Product';
import { getSort } from '../Sort/Sort';
import { getSortOptions, type SortOption } from './SortOption';

type Props = {
  productType?: ProductType;
  sortParam: string;
  onChange: (option: SortOption) => void;
};

export default function SortSelector({
  productType,
  sortParam,
  onChange
}: Readonly<Props>) {
  const { t } = useTranslation('translation');

  const [currentSort, setCurrentSort] = useState<SortOption>();

  const sortOptions = useMemo(() => getSortOptions(productType), [productType]);
  const selectOptions: SelectProps['options'] = sortOptions.map((option) => ({
    value: option.sort,
    label: option.displayName
  }));

  useEffect(() => {
    setCurrentSort(parseSort(sortParam));
  }, [sortParam]);

  const handleChange = (value: string) => {
    const selectedSort = sortOptions.find((option) => option.sort === value);
    if (selectedSort) {
      setCurrentSort(selectedSort);
      onChange(selectedSort);
    }
  };

  return (
    <div className="d-none d-md-flex">
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionSelectedColor: colorPrimary,
              optionSelectedFontWeight: 600,
              optionSelectedBg: 'transparent'
            }
          }
        }}
      >
        <Select
          aria-label={t('translation:SORT')}
          rootClassName="sort-selector font-base-normal"
          value={currentSort?.sort}
          onChange={handleChange}
          options={selectOptions}
          style={{ width: 200 }}
          size={'large'}
          menuItemSelectedIcon={<CheckOutlined />}
          suffixIcon={
            <div className="cursor--default">{currentSort?.icon}</div>
          }
        />
      </ConfigProvider>
    </div>
  );
}

export function getDefaultSort() {
  return getSortOptions()[0];
}

export function parseSort(sortParam: string) {
  const sort = getSort(sortParam);
  return getSortOptions().find(
    (option) =>
      option.column === sort.column && option.direction === sort.direction
  );
}

// Should be moved to ThemeWrapper
const colorPrimary = 'var(--colorButtonPrimaryBg, #007644)';
