import { EpicSort, type EpicTableSortDirection } from '@42.nl/ui';

export type SortOptions = {
  column: string;
  direction: EpicTableSortDirection;
};

type Props = {
  column: string;
  sort: SortOptions;
  onChange: (column: string, value: EpicTableSortDirection) => void;
};

export default function Sort({ column, sort, onChange }: Readonly<Props>) {
  return (
    <EpicSort
      direction={sort.column === column ? sort.direction : 'NONE'}
      onChange={(direction) => onChange(column, direction)}
    />
  );
}

export function getSort(sort: string): SortOptions {
  const [column, directionStr] = sort.split(',');
  const direction = directionStr as EpicTableSortDirection;

  return { column, direction };
}
