import { get, groupBy } from 'lodash';
import { type ParsedFilter } from '../../../../filters/hooks/useParseFilter';
import { type GroupGroup } from '../../../../types/canonical/GroupGroup';
import { type GroupModule } from '../../../../types/canonical/GroupModule';
import { type ProductData } from '../../../../types/canonical/ProductData';
import { type StudyGroup } from '../../../../types/canonical/StudyGroup';
import type Subject from '../../../../types/canonical/Subject';
import { type Product } from '../../../../types/Product';

const getProductGroups = (product: Product): StudyGroup[] | GroupGroup[] => {
  return product.data?.groups || [];
};

const getProductModules = (product: Product): GroupModule[] => {
  return product.data?.modules || [];
};

const getGroups = (
  group: StudyGroup | GroupGroup
): StudyGroup[] | GroupGroup[] => {
  return group.group?.groups || [];
};

const getModules = (group: StudyGroup | GroupGroup): GroupModule[] => {
  return group.group?.modules || [];
};

const getProductSubjects = (
  widgetFilter: ParsedFilter,
  product?: Product
): Subject[] => {
  if (!product) {
    return [];
  }
  const productDataSubjects = getProductDataSubjects(product.data);

  if (Object.keys(widgetFilter).length === 0) {
    return productDataSubjects;
  }

  return productDataSubjects.filter((subject) => {
    return Object.entries(widgetFilter).every(([key, values]) => {
      const value = get(subject, key);
      return value && values.includes(value);
    });
  });
};

const getProductDataSubjects = (data?: ProductData): Subject[] => {
  if (!data) {
    return [];
  }

  return data.subjects || []; // Data should already be sorted
};

const categorizeSubjectsBySubType = (subjects: Subject[]) => {
  return groupBy(
    subjects,
    (subject) => subject.reference.subType ?? 'Uncategorized'
  );
};

export {
  getProductGroups,
  getProductModules,
  getGroups,
  getModules,
  getProductSubjects,
  getProductDataSubjects,
  categorizeSubjectsBySubType
};
