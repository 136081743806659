import { type Group } from '../../types/canonical/Group';
import { type GroupGroup } from '../../types/canonical/GroupGroup';
import { type StudyGroup } from '../../types/canonical/StudyGroup';
import { type ProductType } from '../../types/Product';
import { type WidgetField } from '../../widgets/WidgetField/WidgetField';
import { type StructureStoreReturnType } from '../GroupStructure/structureStore';
import { GroupContents } from './GroupContents';
import { type GroupExtraModes, GroupExtra } from './GroupExtra';

interface GroupBodyProps {
  groupData: Group;
  sortedGroups: GroupGroup[] | StudyGroup[];
  groupSettings: StructureStoreReturnType['groups']['state'];
  year: string;
  level: number;
  changeMode: (mode: GroupExtraModes) => void;
  fields: WidgetField[];
  detailPageType: ProductType;
}

export default function GroupBody({
  groupData,
  year,
  fields,
  changeMode,
  groupSettings,
  sortedGroups,
  level,
  detailPageType
}: Readonly<GroupBodyProps>) {
  return (
    <>
      <GroupExtra
        group={groupData}
        year={year}
        fields={fields}
        onMode={changeMode}
        initialMode={groupSettings.mode}
        isRoot={level === 0}
        detailPageType={detailPageType}
      />

      <div className={`my-2 nested level-${level}`}>
        {sortedGroups.map(
          (child, index) =>
            child.group && (
              <GroupContents
                key={`group-${index}`}
                level={level + 1}
                group={child.group}
                year={year}
                className="my-3"
                expandAll={groupSettings.isAllExpanded}
                fields={fields}
                detailPageType={detailPageType}
              />
            )
        )}
      </div>
    </>
  );
}
