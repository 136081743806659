import { useTranslation } from 'react-i18next';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';

type Props = {
  value: string | undefined;
};

export default function EnumLabel({ value }: Readonly<Props>) {
  const { t } = useTranslation('enum');
  if (!value) {
    return <></>;
  }

  const label = t(value);
  if (label === value) {
    return value;
  }

  return (
    <>
      <span>{value}</span>
      <InfoTooltip tooltip={label} />
    </>
  );
}
