import { useQuery } from '@tanstack/react-query';
import { Loader } from '../../../components';
import type AcademicYear from '../../../types/AcademicYear';
import YearDropdown from './YearDropdown';

interface ProductDetailYearDropdownProps {
  productYearsPromise: (code: string) => Promise<AcademicYear[]>;
  productCode?: string;
  yearExternalId: string;
  onChange: (name: string, value: string) => void | undefined;
}

export default function ProductDetailYearDropdown({
  productYearsPromise,
  productCode,
  yearExternalId,
  onChange
}: Readonly<ProductDetailYearDropdownProps>) {
  const queryState = useQuery({
    queryKey: ['productYears', productCode],
    queryFn: () => {
      if (!productCode) return Promise.resolve([]);
      return productYearsPromise(productCode);
    },
    enabled: !!productCode
  });

  return (
    <Loader state={queryState}>
      {(years) => (
        <YearDropdown
          externalId={yearExternalId}
          initYears={years}
          className="mb-4 d-inline"
          onChange={onChange}
        />
      )}
    </Loader>
  );
}
