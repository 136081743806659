import dayjs, { type Dayjs } from 'dayjs';
import { type FieldState, type FieldValidator } from 'final-form';
import { isEmpty } from 'lodash';
import User from '../../app/users/User';
import { type UserFormData } from '../../app/users/UserList/components/UserForm/UserForm';
import i18n from '../i18n';

export function isEmail(email: string | undefined): string | undefined {
  if (email && email.indexOf('@') === -1) {
    return i18n.t('validation:INVALID_EMAIL');
  } else {
    return undefined;
  }
}

export async function isEmailUnique(
  email: string | undefined,
  allValues: any,
  meta?: FieldState<string>
): Promise<string | undefined> {
  if (meta && !meta.active) {
    return Promise.resolve(undefined);
  }

  if (!email) {
    return Promise.resolve(undefined);
  }

  const page = await User.page({ email });
  const content = page.content;

  const lowerCasedEmail = email.toLowerCase();

  const formValues = allValues as UserFormData;

  const isEmailTaken = content.some(
    (user) =>
      (user.email || '').toLowerCase() === lowerCasedEmail &&
      user.id !== formValues.id
  );

  return isEmailTaken
    ? Promise.resolve(i18n.t('validation:EMAIL_TAKEN'))
    : Promise.resolve(undefined);
}

export function isDateBefore(maxDate: Dayjs): FieldValidator<Date> {
  return (value: Date): string | undefined => {
    if (dayjs(value).isBefore(maxDate)) {
      return undefined;
    }

    return i18n.t('validation:DATE_AFTER_MAX', {
      maxDate: maxDate.toLocaleString()
    });
  };
}

export function matchesRegex(
  regex: RegExp,
  name: string,
  example: string
): FieldValidator<string> {
  return (value: string): string | undefined => {
    if (!value || isEmpty(value) || regex.test(value)) {
      return undefined;
    }

    return i18n.t('validation:VALUE_DOES_NOT_MATCH_PATTERN', {
      name,
      example
    });
  };
}
