import { useMemo } from 'react';
import { isEmpty } from 'lodash';

export type ParsedFilter = { [key: string]: string[] };

export const useParseFilter = (filter?: string): ParsedFilter => {
  return useMemo(() => {
    if (!filter) return {};

    return filter.split('&').reduce((acc, condition) => {
      const [key, value] = condition.split('=').map((v) => v.trim());
      if (!isEmpty(key)) {
        acc[key] = value.split(',').map((v) => v.trim());
      }
      return acc;
    }, {} as ParsedFilter);
  }, [filter]);
};
