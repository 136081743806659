type Props = {
  isToggled: boolean;
};

export default function MobileMenuOverlay({ isToggled }: Readonly<Props>) {
  return (
    <div
      id="menuOverlay"
      className={`mobile-menu-canvas-overlay bg-dark position-fixed w-100 h-100 ${
        isToggled ? ' show' : ' hide'
      }`}
    />
  );
}
