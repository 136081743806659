import { useTranslation } from 'react-i18next';
import CollapsibleCheckboxMultiSelectField from '../../components/CollapsibleCheckboxMultiSelect/CollapsibleCheckboxMultiSelectField';
import { widgetApi } from '../service/widgetApi';
import { useWidgetSelectOptions } from './hooks/useWidgetSelectOptions';

interface WidgetSelectProps {
  id: string;
  entityType: string | undefined;
}

export default function WidgetSelect({
  id,
  entityType
}: Readonly<WidgetSelectProps>) {
  const { t } = useTranslation(['widget']);

  const { data: widgets } = widgetApi.list.useQuery();

  const options = useWidgetSelectOptions(widgets, entityType);

  return (
    <CollapsibleCheckboxMultiSelectField
      name={id}
      className="mb-3"
      label={t('widget:ADD_WIDGET')}
      keyForOption={(widget) => widget.id.toString()}
      labelForOption={(widget) => widget.name}
      options={options}
    />
  );
}
