import { type QueryClient } from '@tanstack/react-query';
import { type router } from 'react-query-kit';

// Clears all cache for the given router, use more specific cache invalidation if possible.
export const invalidateQueryCaches = async (
  queryClient: QueryClient,
  queryRouter: ReturnType<typeof router>
) => {
  const queryKeys = Object.keys(queryRouter).filter(
    (key) => queryRouter[key]?.getKey
  );

  for (const key of queryKeys) {
    const queryKey = queryRouter[key].getKey();
    if (queryKey) {
      await queryClient.invalidateQueries({ queryKey });
    }
  }
};
