import type { GroupGroup } from '../../../../../../types/canonical/GroupGroup';
import type { GroupModule } from '../../../../../../types/canonical/GroupModule';
import type { StudyGroup } from '../../../../../../types/canonical/StudyGroup';
import {
  type ProductTreeNodeType,
  ProductTreeNodeTypeEnum
} from '../types/ProductTreeNodeType';

const isProductTreeNodeGroup = (
  node: ProductTreeNodeType
): node is StudyGroup | GroupGroup => {
  return (node as GroupGroup).group !== undefined;
};

const isProductTreeNodeModule = (
  node: ProductTreeNodeType
): node is GroupModule => {
  return (node as GroupModule).module !== undefined;
};

const getProductTreeNodeTypeName = (
  isGroup: boolean,
  isModule: boolean
): ProductTreeNodeTypeEnum => {
  if (isGroup) {
    return ProductTreeNodeTypeEnum.STUDY_OR_GROUP_TO_GROUP;
  } else if (isModule) {
    return ProductTreeNodeTypeEnum.GROUP_TO_MODULE;
  }

  throw new Error('Unknown node type');
};

export {
  isProductTreeNodeGroup,
  isProductTreeNodeModule,
  getProductTreeNodeTypeName
};
