import { useReferences } from '../../references/useReferences';
import type Description from '../../types/canonical/Description';
import DescriptionItem from './DescriptionItem/DescriptionItem';

type Props = {
  type: string;
  year: string;
  descriptions?: Description[];
  expanded?: boolean;
};

export default function DescriptionList({
  type,
  year,
  descriptions,
  expanded
}: Readonly<Props>) {
  const { references: types } = useReferences(type, year, true);

  return (
    <>
      {types.map((type_, index) => (
        <DescriptionItem
          key={type_.externalId}
          type={type_}
          expanded={expanded}
          descriptions={
            descriptions?.filter(
              (description) => description.type?.id === type_.externalId
            ) || []
          }
          className={index < types.length - 1 ? 'mb-2' : ''}
        />
      ))}
    </>
  );
}
