import { PushpinOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface RequiredIconProps {
  isRequired?: boolean;
}

const RequiredIcon = ({ isRequired }: RequiredIconProps) => {
  const { t } = useTranslation(['widget']);
  const label = t('widget:TYPE.MATRIX.TABLE.REQUIRED.TITLE');

  if (!isRequired) {
    return null;
  }

  return (
    <i aria-label={label}>
      <Tooltip title={label}>
        <PushpinOutlined aria-hidden={true} />
      </Tooltip>
    </i>
  );
};

export default RequiredIcon;
