import { type Url, urlBuilder } from '@42.nl/react-url';
import { FACULTY_APP_URL } from '../../faculties/FacultyLinks';
import { QUALIFICATION_APP_URL } from '../../qualifications/QualificationLinks';
import { STUDY_APP_URL } from '../../studies/StudyLinks';
import { GROUP_APP_URL } from '../../types/GroupLinks';
import { MODULE_APP_URL } from '../../types/ModuleLinks';
import {
  defaultTabPageQueryParams,
  type TabPageQueryParams
} from '../hooks/useTabPageQueryParams.hook';
import type Tab from '../types/Tab';

export const TAB_APP_URL = '/tabs';

export function toTabsApp(): Url {
  return TAB_APP_URL;
}

export function toTabPageUrl(queryParams?: Partial<TabPageQueryParams>): Url {
  return urlBuilder({
    url: TAB_APP_URL,
    queryParams,
    defaultQueryParams: defaultTabPageQueryParams()
  });
}

export function getTabUrl(tab?: Tab): string | null {
  if (!tab) {
    return null;
  }

  switch (tab.entityType) {
    case 'FACULTY':
      return FACULTY_APP_URL;
    case 'QUALIFICATION':
      return QUALIFICATION_APP_URL;
    case 'STUDY':
      return STUDY_APP_URL;
    case 'GROUP':
      return GROUP_APP_URL;
    case 'MODULE':
      return MODULE_APP_URL;
    default:
      return null;
  }
}
