import { useTranslation } from 'react-i18next';

type Props = {
  language: string;
  className?: string;
};

export default function Logo({ language, className }: Readonly<Props>) {
  const { t } = useTranslation('topbar');

  return (
    <a
      href="/"
      className={`logo ${language} ${className}`}
      aria-label={t('TOPBAR.GO_TO.HOME')}
    ></a>
  );
}
