import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { Expander } from '../../components';
import { ExpandAll } from '../../components/ExpandAll/ExpandAll';
import { LanguageLabel } from '../../i18n';
import { ReferenceType } from '../../references/Reference';
import { useReferences } from '../../references/useReferences';
import { type Group } from '../../types/canonical/Group';
import { sortGroups } from '../../types/SimpleGroup';
import { type WidgetField } from '../../widgets/WidgetField/WidgetField';
import GroupBody from './GroupBody';
import { type GroupExtraModes } from './GroupExtra';
/* eslint-disable import/order */
import { useDispatch, useSelector } from 'react-redux';
import {
  type StructureStoreDispatch,
  type StructureStoreReturnType
} from '../GroupStructure/structureStore';
import {
  setGroup,
  setGroupMode,
  toggleGroup
} from '../GroupStructure/structureSlice';
import { type ProductType } from '../../types/Product';
import { getDefaultCode } from '../../types/canonical/ProductData';
/* eslint-enable import/order */

type Props = {
  group: Group;
  year: string;
  level?: number;
  className?: string;
  expandAll?: boolean;
  fields: WidgetField[];
  detailPageType: ProductType;
};

export function GroupContents({
  group,
  year,
  level = 1,
  className,
  expandAll,
  fields,
  detailPageType
}: Readonly<Props>) {
  const { references: types } = useReferences(ReferenceType.GROUP_TYPE);
  const dispatch: StructureStoreDispatch = useDispatch();

  const ensuredId = useMemo(() => {
    return getDefaultCode(group);
  }, [group]);

  const groupSettings = useSelector(
    (state: StructureStoreReturnType) => state.groups[ensuredId]
  );

  useEffect(() => {
    if (!groupSettings) {
      dispatch(setGroup({ code: ensuredId }));
    }
  }, [dispatch, ensuredId, groupSettings]);

  useEffect(() => {
    dispatch(
      setGroup({
        code: ensuredId,
        settings: {
          isExpanded: expandAll,
          isAllExpanded: expandAll
        }
      })
    );
  }, [dispatch, ensuredId, expandAll]);

  const toggleExpand = useCallback(() => {
    dispatch(toggleGroup({ code: ensuredId }));
  }, [dispatch, ensuredId]);

  const toggleExpandAll = useCallback(
    (expand: boolean) => {
      dispatch(
        setGroup({
          code: ensuredId,
          settings: {
            isExpanded: expand,
            isAllExpanded: expand
          }
        })
      );
    },
    [dispatch, ensuredId]
  );

  const changeMode = useCallback(
    (mode: GroupExtraModes) => {
      if (groupSettings?.code) {
        dispatch(
          setGroupMode({
            code: groupSettings.code,
            mode
          })
        );
      }
    },
    [dispatch, groupSettings]
  );

  const sortedGroups = useMemo(
    () => sortGroups(group.groups, types),
    [group.groups, types]
  );

  if (!groupSettings) {
    return <></>;
  }

  if (level < 1) {
    return (
      <GroupBody
        groupData={group}
        year={year}
        fields={fields}
        changeMode={changeMode}
        groupSettings={groupSettings}
        sortedGroups={sortedGroups}
        level={level}
        detailPageType={detailPageType}
      />
    );
  }

  return (
    <Card className={`my-2 ${className}`}>
      <CardHeader
        className={classNames({ clickable: true })}
        onClick={toggleExpand}
      >
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-column flex-grow-1">
            <LanguageLabel names={group.names} />
          </div>

          <ExpandAll
            expand={groupSettings.isAllExpanded}
            onToggle={toggleExpandAll}
            showLabel={false}
            className={'m-r-5'}
          />
          <Expander
            size={24}
            collapsed={!groupSettings.isExpanded}
          />
        </div>
      </CardHeader>

      <Collapse
        isOpen={groupSettings.isExpanded}
        style={{ fontSize: '1.5rem' }}
      >
        <CardBody className="px-3 py-1">
          <GroupBody
            groupData={group}
            year={year}
            fields={fields}
            changeMode={changeMode}
            groupSettings={groupSettings}
            sortedGroups={sortedGroups}
            level={level}
            detailPageType={detailPageType}
          />
        </CardBody>
      </Collapse>
    </Card>
  );
}
