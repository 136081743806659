import { get, makeResource } from '@42.nl/spring-connect';
import { type Product, type ProductType } from './Product';

const baseUrl = '/api/link';

export default class Link extends makeResource<Link>(baseUrl) {
  source!: Product;
  target!: Product;

  static getRelated(
    product: Product,
    targetType: ProductType
  ): Promise<Product[]> {
    return get(baseUrl, { productId: product.id, targetType });
  }

  static readonly QUERY_KEY = 'sourceTargetLinks';
}
