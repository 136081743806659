import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

type Props = {
  tooltip: string | undefined;
};

export function InfoTooltip({ tooltip }: Props) {
  if (!tooltip) {
    return <></>;
  }

  return (
    <span
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Tooltip title={tooltip}>
        <InfoCircleFilled
          className="ms-1 text-primary"
          size={15}
        />
      </Tooltip>
    </span>
  );
}
