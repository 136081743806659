import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductTreeBulkToggleCollapseButton from './ProductTreeBulkToggleCollapseButton';
import ProductTreeBulkToggleExpandButton from './ProductTreeBulkToggleExpandButton';
import { type ProductTreeBulkToggleMenuConfigItem } from './types/ProductTreeBulkToggleMenuConfig';
import { type ProductTreeBulkToggleMode } from './types/ProductTreeBulkToggleMode';

const ProductTreeBulkToggleDropdownItem = ({
  config,
  onAction
}: {
  config: ProductTreeBulkToggleMenuConfigItem;
  onAction: (action: ProductTreeBulkToggleMode) => void;
}) => {
  const { t } = useTranslation(['widget']);

  return (
    <div className="d-flex align-items-center w-100 gap-5">
      <div className="flex-grow-1 d-flex align-items-center gap-2">
        {config.icon}
        <div>{t(config.labelKey)}</div>
      </div>
      <Space className="ms-2">
        <ProductTreeBulkToggleExpandButton
          label={t(config.expandKey)}
          onAction={() => onAction(config.expandAction)}
        />
        <ProductTreeBulkToggleCollapseButton
          label={t(config.collapseKey)}
          onAction={() => onAction(config.collapseAction)}
        />
      </Space>
    </div>
  );
};

export default ProductTreeBulkToggleDropdownItem;
