import { type Key, useMemo } from 'react';
import { Table as AntdTable } from 'antd';
import type { TableProps as AntdTableProps } from 'antd';
import classNames from 'classnames';

export interface TableRecord {
  key: Key;
}

interface TableProps<T> extends AntdTableProps<T> {
  scrollable?: boolean;
  size?: 'small' | 'middle' | 'large';
}

export function Table<T>({
  scrollable,
  size = 'small',
  ...props
}: Readonly<TableProps<T>>) {
  const scroll = useMemo(
    () => (scrollable ? { x: 'max-content' } : {}),
    [scrollable]
  );
  const computedClassName = useMemo(
    () => classNames({ 'table--condensed': size === 'small' }),
    [size]
  );
  const computedRowClassName = useMemo(
    () => classNames('font-base-normal', { 'cursor--pointer': props.onRow }),
    [props.onRow]
  );

  return (
    <AntdTable
      scroll={scroll}
      tableLayout="auto"
      className={computedClassName}
      rowClassName={computedRowClassName}
      size={size}
      {...props}
    />
  );
}
