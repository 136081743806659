import { type ReactNode } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from '@timeedit/ui-components';
import { ModalHeader as ModalHeaderReactstrap } from 'reactstrap';

interface ModalHeaderCustomProps {
  onClose: () => void;
  children: ReactNode;
}

const ModalHeaderCustom = ({ onClose, children }: ModalHeaderCustomProps) => {
  return (
    <ModalHeaderReactstrap
      toggle={onClose}
      close={
        <Button
          icon={<CloseOutlined />}
          onClick={onClose}
          className="sg-ml-auto"
        />
      }
    >
      {children}
    </ModalHeaderReactstrap>
  );
};

export default ModalHeaderCustom;
