import { type ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import { QuickviewItem } from '../../../components/Quickview/components/QuickviewItem';
import { QuickviewTitle } from '../../../components/Quickview/components/QuickviewTitle';
import { getText } from '../../../i18n/LocalizedText';
import { WidgetType } from '../../types/WidgetType';
import { getWidgetFieldLabel } from '../../WidgetField/utils/WidgetFieldUtils';
import { type WidgetField } from '../../WidgetField/WidgetField';

type Props = {
  field: WidgetField;
  type?: WidgetType;
  isEmpty: boolean;
  hideHeader?: boolean;
  sideBarClasses?: string;
  viewClasses?: string;
  children: () => ReactNode;
  isTitle: boolean;
};

export function FieldSection({
  field,
  type,
  isEmpty,
  hideHeader,
  sideBarClasses,
  viewClasses,
  children,
  isTitle
}: Readonly<Props>) {
  const tooltip = useMemo(() => {
    return getText(field.tooltips, undefined);
  }, [field.tooltips]);

  const label = useMemo(() => {
    return getWidgetFieldLabel(field);
  }, [field]);

  if (isEmpty && field.hideEmpty) {
    return <></>;
  }

  if (type === WidgetType.PRIMARY) {
    return isTitle ? (
      <QuickviewTitle>{children()}</QuickviewTitle>
    ) : (
      <QuickviewItem
        hideLabel={hideHeader}
        label={label}
        tooltip={tooltip}
      >
        {children()}
      </QuickviewItem>
    );
  }

  if (type !== WidgetType.SIDEBAR) {
    return (
      <section className="details__content__main__section">
        {!hideHeader && (
          <div className="d-flex flex-row">
            <h2 className="details__content__main-name d-flex flex-column flex-grow-1 mt-2">
              {label}
            </h2>
          </div>
        )}
        {tooltip && <p>{tooltip}</p>}
        <div className={viewClasses}>{children()}</div>
      </section>
    );
  }

  return (
    <section
      className={classNames('details__content__section d-flex', sideBarClasses)}
    >
      <div className="flex-grow-1 font-base-strong me-3">
        {label}
        <InfoTooltip tooltip={tooltip} />
      </div>
      <div
        className={`details__content__main__section__value text-end ${viewClasses || 'text-break'}`}
      >
        {children()}
      </div>
    </section>
  );
}
