import { type Key } from 'react';

/**
 * Generates a unique identifier for a node in the tree, considering its parent hierarchy.
 * A node can appear multiple times in the tree, so this ensures each instance is uniquely identified.
 */
const getProductTreeNodeCompositeUid = (
  parentNodeCompositeUID: Key,
  currentNodeUid: string
): string => {
  return parentNodeCompositeUID
    ? `${parentNodeCompositeUID}___${currentNodeUid}`
    : currentNodeUid;
};

export { getProductTreeNodeCompositeUid };
