import _, { isEmpty } from 'lodash';
import { displayName, ReferenceType } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import type License from '../../../types/canonical/License';
import { type WidgetType } from '../../types/WidgetType';
import { type WidgetField } from '../../WidgetField/WidgetField';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  licenses: License[];
  year: string;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function LicensesItem({
  field,
  type,
  licenses,
  year,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { references } = useReferences(ReferenceType.SOFTWARE, year);

  const names = _(licenses)
    .groupBy((license) => license.type.id)
    .keys()
    .map((id: string) => displayName(id, references, id) || '')
    .sort()
    .value();

  return (
    <FieldSection
      field={field}
      type={type}
      hideHeader={hideHeader}
      isEmpty={isEmpty(names)}
      isTitle={isTitle}
    >
      {() => (
        <>
          {names.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </>
      )}
    </FieldSection>
  );
}
