import { makeResource } from '@42.nl/spring-connect';

const baseUrl = '/api/year';

export default class AcademicYear extends makeResource<AcademicYear>(baseUrl) {
  id!: number;
  externalId!: string;
  code?: string;
  dutchName?: string;
  englishName?: string;
  startDate!: Date;
  endDate!: Date;
  active!: boolean;
}
