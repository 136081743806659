import { type ProductDetailQueryParams } from '../../types/Product';
import { getYearService } from '../../years/YearService';

export function defaultModuleDetailQueryParams(): ProductDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info',
    mainTab: 'module'
  };
}
