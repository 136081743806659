import { Field } from 'react-final-form';
import CollapsibleCheckboxMultiSelect from './CollapsibleCheckboxMultiSelect';
import { type CollapsibleCheckboxMultiSelectSharedProps } from './types/CollapsibleCheckboxMultiSelectSharedProps';

interface CollapsibleCheckboxMultiSelectFieldProps<T>
  extends CollapsibleCheckboxMultiSelectSharedProps<T> {
  name: string;
}

export default function CollapsibleCheckboxMultiSelectField<T>({
  name,
  label,
  tooltip,
  className,
  color,
  options,
  keyForOption,
  labelForOption
}: Readonly<CollapsibleCheckboxMultiSelectFieldProps<T>>) {
  return (
    <Field<T[]>
      name={name}
      render={({ input }) => {
        return (
          <div>
            <CollapsibleCheckboxMultiSelect<T>
              id={name}
              label={label}
              tooltip={tooltip}
              className={className}
              color={color}
              value={input.value || []}
              options={options}
              keyForOption={keyForOption}
              labelForOption={labelForOption}
              onChange={input.onChange} // Propagate changes to React Final Form
            />
          </div>
        );
      }}
    />
  );
}
