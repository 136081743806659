import { get, post } from '@42.nl/spring-connect';

export type UserRole = 'ADMIN' | 'USER';

/*
  Represents a user which is currently logged-in.
*/
export type CurrentUser = {
  id: number;
  name?: string;
  roles: UserRole[];
};

export function isAdmin(user: CurrentUser) {
  return user.roles.includes('ADMIN');
}

export function getCurrent(): Promise<CurrentUser | undefined> {
  return get('/api/authentication/current');
}

export function getToken(token: string): Promise<CurrentUser | undefined> {
  return post('/api/token/authenticate', {
    token,
    logoutUrl: '/token?action=logout'
  });
}
