import { type ProductDetailQueryParams } from '../../types/Product';
import { getYearService } from '../../years/YearService';

export function defaultGroupDetailQueryParams(): ProductDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info',
    mainTab: 'group'
  };
}
