type QuickViewTitleProps = {
  children: React.ReactNode;
};

export function QuickviewTitle({ children }: Readonly<QuickViewTitleProps>) {
  if (!children) {
    return <></>;
  }

  return <span className="element-name">{children}</span>;
}
