import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { Expander } from '../Expander/Expander';
import { FilterLabel } from '../FilterLabel/FilterLabel';
import { type CollapsibleCheckboxMultiSelectSharedProps } from './types/CollapsibleCheckboxMultiSelectSharedProps';

class CheckboxState<T> {
  option: T;
  checked: boolean = false;
  uniqueKey: string;

  constructor(option: T, checked: boolean, uniqueKey: string) {
    this.option = option;
    this.checked = checked;
    this.uniqueKey = uniqueKey;
  }
}

interface CollapsibleCheckboxMultiSelectProps<T>
  extends CollapsibleCheckboxMultiSelectSharedProps<T> {
  id: string;
  value: T[];
  children?: React.ReactNode;
  onChange: (value: T[]) => void;
}

/**
 * Multi-select checkbox with a collapse toggle.
 * Note that we do not use the CheckboxMultipleSelect.
 * This is because it does not render properly when options dynamically change.
 */
export default function CollapsibleCheckboxMultiSelect<T>({
  id,
  label,
  hideLabel,
  tooltip,
  options,
  className,
  color,
  value,
  children,
  keyForOption,
  labelForOption,
  optionsShouldAlwaysContainValue,
  onChange
}: Readonly<CollapsibleCheckboxMultiSelectProps<T>>) {
  const [collapsed, setCollapsed] = useState(false);

  const getKey = useCallback(
    (option: T, index: number) => keyForOption?.(option) ?? index.toString(),
    [keyForOption]
  );

  const ensuredOptions = useMemo(() => {
    const currentValues = value || [];

    const selectedValuesNotInOptions = currentValues.filter((selectedValue) =>
      options.every(
        (option, optionIndex) =>
          getKey(option, optionIndex) !== getKey(selectedValue, optionIndex)
      )
    );

    const allOptions = optionsShouldAlwaysContainValue
      ? [...options, ...selectedValuesNotInOptions]
      : options;

    return allOptions.map((option, index) => {
      const uniqueKey = getKey(option, index);
      const isChecked = currentValues.some(
        (selectedValue) => uniqueKey === getKey(selectedValue, 0)
      );

      return new CheckboxState(option, isChecked, uniqueKey);
    });
  }, [options, value, optionsShouldAlwaysContainValue, getKey]);

  function toggle() {
    setCollapsed(!collapsed);
  }

  function onChangeCheckbox(changedValue: CheckboxState<T>, checked: boolean) {
    changedValue.checked = checked;

    const selected = ensuredOptions
      .filter((value_) => value_.checked)
      .map((value_) => value_.option);

    onChange(selected);
  }

  return (
    <div
      className={classNames('form-group', className)}
      color={color}
      aria-label={label}
    >
      {!hideLabel && (
        <div
          className="clickable d-flex flex-row justify-content-between"
          onClick={toggle}
        >
          <FilterLabel
            name={id}
            label={label}
            tooltip={tooltip}
            className="mb-0"
          />

          <Expander
            size={24}
            collapsed={collapsed}
          />
        </div>
      )}

      {!collapsed && ensuredOptions.length > 0 && (
        <>
          {ensuredOptions.map((optionState) => (
            <Checkbox
              key={optionState.uniqueKey}
              id={`${id}-${optionState.uniqueKey}`}
              checked={optionState.checked}
              className="form__checkbox"
              onChange={(checkboxChangeEvent) =>
                onChangeCheckbox(
                  optionState,
                  checkboxChangeEvent.target.checked
                )
              }
            >
              {labelForOption(optionState.option)}
            </Checkbox>
          ))}
        </>
      )}
      {!collapsed && <>{children}</>}
    </div>
  );
}
