import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, type MenuProps } from 'antd';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../../../../authentication/useAuthentication';
import { toFilterApp } from '../../../../../../filters/links';
import { toImportApp } from '../../../../../../import/links';
import { toJobApp } from '../../../../../../jobs/links';
import { toLabelsApp } from '../../../../../../labels/links';
import { toParameterApp } from '../../../../../../parameters/links';
import { toPeriodApp } from '../../../../../../periods/links';
import { toReferencesApp } from '../../../../../../references/links';
import { toTabsApp } from '../../../../../../tabs/utils/tabUrlUtils';
import { toWidgetsApp } from '../../../../../../widgets/links';
import { toYearsApp } from '../../../../../../years/links';

export default function AdministrationNav() {
  const { t } = useTranslation(['topbar', 'translation', 'application']);
  const navigate = useNavigate();

  const auth = useAuthentication();
  const isAdmin = auth?.currentUser?.roles.includes('ADMIN');

  if (!isAdmin) return <></>;

  const menuItems: MenuProps['items'] = sortBy(
    [
      {
        key: 'filters',
        label: t('TOPBAR.FILTERS'),
        onClick: () => navigate(toFilterApp())
      },
      {
        key: 'labels',
        label: t('TOPBAR.LABELS'),
        onClick: () => navigate(toLabelsApp())
      },
      {
        key: 'parameters',
        label: t('TOPBAR.PARAMETERS'),
        onClick: () => navigate(toParameterApp())
      },
      {
        key: 'references',
        label: t('TOPBAR.REFERENCES'),
        onClick: () => navigate(toReferencesApp())
      },
      {
        key: 'widgets',
        label: t('TOPBAR.WIDGETS'),
        onClick: () => navigate(toWidgetsApp())
      },
      {
        key: 'tabs',
        label: t('TOPBAR.TABS'),
        onClick: () => navigate(toTabsApp())
      },
      {
        key: 'import',
        label: t('TOPBAR.IMPORT'),
        onClick: () => navigate(toImportApp())
      },
      {
        key: 'academic_years',
        label: t('TOPBAR.ACADEMIC_YEARS'),
        onClick: () => navigate(toYearsApp())
      },
      {
        key: 'periods',
        label: t('TOPBAR.PERIODS'),
        onClick: () => navigate(toPeriodApp())
      },
      {
        key: 'jobs',
        label: t('TOPBAR.JOBS'),
        onClick: () => navigate(toJobApp())
      }
    ],
    ['label']
  );

  if (!isAdmin) return <></>;

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={['click']}
      className="nav-link"
    >
      <div className="sg-cursor-pointer sg-inline-flex sg-items-center sg-gap-2">
        <SettingOutlined />
        <span>{t('TOPBAR.ADMINISTRATION')}</span>
        <DownOutlined className="sg-w-[12px] sg-h-[12px]" />
      </div>
    </Dropdown>
  );
}
