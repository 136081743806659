/* eslint-disable */
import { configureStore } from "@reduxjs/toolkit";
import { GroupExtraModes } from "../GroupContents/GroupExtra";
import structureSlice from "./structureSlice";

/* eslint-enable */

export interface StructureGroupsState {
  [key: string]: StructureGroupSettings;
}

export interface StructureGroupSettings {
  code: string;
  isExpanded: boolean;
  isAllExpanded: boolean;
  isFetched: boolean;
  mode: GroupExtraModes;
}

export const structureStore = configureStore({
  reducer: {
    groups: structureSlice
  }
});

export type StructureStoreReturnType = ReturnType<
  typeof structureStore.getState
>;
export type StructureStoreDispatch = typeof structureStore.dispatch;
