import {
  useQuery,
  type UseQueryResult,
  type UseQueryOptions
} from '@tanstack/react-query';
import { type Group } from '../../../types/canonical/Group';
import { type GroupModule } from '../../../types/canonical/GroupModule';
import SimpleGroup from '../../../types/SimpleGroup';
import SimpleModule, { type ModuleRow } from '../../../types/SimpleModule';

type GroupModulesTypeProps = {
  group: Group;
  options?: Partial<UseQueryOptions<ModuleRow[], Error>>;
};

export default function useGroupModules({
  group,
  options
}: Readonly<GroupModulesTypeProps>): UseQueryResult<ModuleRow[], Error> {
  const queryKey = SimpleGroup.getQueryKey(group.uid, group.year.id);
  return useQuery<ModuleRow[], Error>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKey,
    queryFn: () => loadModuleRows(group),
    enabled: !!group.uid,
    ...options
  });
}

export async function loadModuleRows(group: Group): Promise<ModuleRow[]> {
  const page = await SimpleModule.page({
    group: group.uid,
    year: group.year.id,
    size: 999
  });

  const moduleMap = new Map(
    page.content.map((module) => [module.data.uid, module])
  );

  return (group.modules || [])
    .filter((structure: GroupModule) => structure?.module)
    .map((structure: GroupModule) => ({
      structure,
      module: moduleMap.get(structure.module.uid)!
    }))
    .filter((row: ModuleRow) => row.module);
}
